#news {
    .section {
        padding-bottom: 0;
        position: relative;
        display: flex;
        align-items: flex-end;
        padding: 0;

        .section-content {
            height: 100%;
            width: 100%;
        }

        .lastNews-img {
            position: absolute;
            bottom: 0;
            right: 0;
            height: 500px;
            width: 500px;
            z-index: 1;
            opacity: 0.5;

        }

        .button, button {
            z-index: 30;
            position: relative;
        }

        .lastNews {
            position: relative;
            z-index: 3;
            padding-bottom: 165px;
            padding-left: 100px;
        }

        h1 {
            font-family: 'Myriad';
            font-size: 20px;
            font-weight: 100;
            margin-bottom: 30px;
        }

        h2 {
            font-family: 'Myriad';
            font-size: 38px;
            font-weight: 300;
            margin-bottom: 20px;
        }


        .author {
            color: #997c6d;
            span {
                color: #838383;
            }
        }

        .paragraph {
            max-width: 600px;
            text-align: justify;
            margin-bottom: 30px;
            font-family: 'Open Sans';
            font-size: 12px;
        }

        .header {
            margin-bottom: 10px;
        }
    
        .divider, .date {
            color: #838383;
        }

        .divider {
            margin: 0 10px;
        }


    }

    .big-divider {
        width: 100%;
        height: 10px;
        background-color: #c08e55;
        box-shadow: 0 0 500px 100px #000;
        z-index: 2;
        position: relative;
    }

    .articles {
        margin: 50px 0;
        padding-left: 20px;
        height: 380px;
        width: 100vw;
        z-index: 4;
        position: relative;

        .demo-vertical-track {
            display: none;
        }

        .articles-container {
            display: flex;
            column-gap: 30px;
        }

        .articleCard {
            height: 350px;
            width: 300px;
            background-color: #201a1d;
            border-radius: 10px;
            overflow: hidden;
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            font-family: 'Open Sans', sans-serif;

            &:hover {
                box-shadow: 0 0 50px #c08e55ac;
            }

            .image {
                height: 60%;
                width: 100%;
            }

            .text {
                padding: 20px;
                padding-top: 20px;

                .flex {
                    align-items: center;
                    font-weight: 200;
                }

                .divider {
                    margin: 0 10px;
                    opacity: 0.5;
                }

                .author {
                    opacity: 0.5;

                    span {
                        opacity: 1;
                        color: #c08e55;
                    }
                }

                .description {
                    margin-top: 10px;
                    font-size: 15px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    #news {
        .big-divider {
            box-shadow: none;
        }

        .section {
            min-height: 550px;
            .lastNews-img {
                height: 250px;
                width: 250px;
                opacity: 0.5!important;
            }

            .lastNews {
                padding-bottom: 50px;
                padding:  50px 30px;
            }
            
            h2 {
                font-size: 25px;
            }



            .paragraph {
                font-size: 13px;
            }
        }
    }
}