.items-card {
    width: 205px;
    border-radius: 5px;
    //overflow: hidden;
    margin: 7px 5px;
    font-family: 'Open Sans', sans-serif;
    transition: all 0.3s ease-in-out;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        width: 207px;
        height: 237px;
        border-radius: 5px;
        background: linear-gradient(180deg, #cf914b 0%, rgba(23,19,21,1) 70%, #cf914b7c 100%);
    }

    &:hover {
        box-shadow:  0 0 50px #000;
    }

    .header {
        height: 118px;
        width: 100%;
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        .img-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 50px;
            width: 50px;
            border: solid 1px #f6b66b;
            background-color: #0907081f;
            padding: 5px;

            .img-item {
                height: 100%;
                width: 100%;
            }
        }

        h4 {
            font-size: 13.3px;
            margin-bottom: 7px;
        }
    }

    .divider {
        height: 3.55px;
        width: 100%;
        background-color: #ffab48;
        position: relative;
        z-index: 2;
    }

    .description {
        height: 113px;
        width: 100%;
        padding: 10px;
        padding-top: 2px;
        padding-bottom: 0;
        background-color: #1c1515;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 2;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        h5 {
            color: #ffab48;
            text-align: center;
            font-size: 9.25px;
            margin-bottom: 5px;
        }

        p {
            font-size: 10px;
            letter-spacing: -0.5px;
            margin-bottom: 8px;
            line-height: 12px;
        }

        span {
            color: #ffab48;
            font-weight: 700;
            font-size: 14px;
            white-space: nowrap;
            font-family: 'Open Sans Bold';
        }

        .flex {
            align-items: center;
        }

        img {
            height: 25px;
            margin-right: 3px;
        }

        .custom-button {
            margin-left: 10px;
        }
    }

}