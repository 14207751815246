#home {
    background-color: #000;


    .scrollTop {
        position: fixed;
        bottom: 10%;
        right: 5%;
        border: solid 1px #918173;
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99;
        cursor: pointer;
        transition: opacity 0.3s ease-in-out;
        opacity: 0;

        &.show {
            opacity: 1;
        }

        .arrow {
            border-top: solid 2px #918173;
            border-left: solid 2px #918173;
            height: 10px;
            width: 10px;
            transform: translateY(30%) rotate(45deg);
        }
    }

    .scrollDown {
        position: absolute;
        bottom: 5%;
        left: 50%;
        transform: translateX(-50%);
        border: solid 1px #918173;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99;
        border-radius: 100px;
        cursor: pointer;
        

        &::after {
            content: '向下捲動';
            position: absolute;
            top: -25px;
            left: 50%;
            transform: translateX(-50%);
            width: 100px;
            text-align: center;
            font-family: 'Open Sans';
            font-size: 12px;
            color: #918173;
        }


        .arrow {
            border-top: solid 2px #918173;
            border-left: solid 2px #918173;
            height: 10px;
            width: 10px;
            animation: scrollDown 1.5s ease-in-out infinite;
        }
    }

    h2 {
        font-size: 20px;
        margin-bottom: 45px;
    }

    header {
        height: 100vh;
        width: 100%;
        padding: 0 130px;
        padding-top: 90px;
        display: flex; 
        position: relative;

        .serverStatus {
            font-family: 'Friz Quadrata';
            font-size: 10px;
            position: absolute;
            top: 20px;
            right: 25px;
            span {
                color: #41b561;
            }
        }

        .social-icons {
            position: absolute;
            top: 200px;
            right: 0;
            z-index: 99;

            a {
                display: block;
                height: 50px;
                width: 50px;
            }
        }

        .header-img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background: linear-gradient(to top, #0b0a0a, transparent 50%);    
            }
        }

        .header-container {
            max-width: 500px;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 75%;
            position: relative;
            z-index: 2;
            padding-top: 20px;

            .subtitle {
                font-size: 18px;
                font-family: 'Friz Quadrata';
                margin-bottom: 40px;
                max-width: 430px;
                text-align: justify;
            }

            .logo {
                min-height: 310px;
                min-width: 400px;
                height: 30vw;
                width: 35vw;
            }

            p {
                font-family: 'Friz Quadrata';
            }

            button {
                align-items: center;
                justify-content: center;
                padding: 0;
                width: 290px;
                height: 50px;

                img {
                    margin-right: 10px;
                }
            }
        }
    }

    .socialMediaFooter {
        margin-bottom: 0;
        padding-bottom: 65px;
        padding-top: 65px;

        h2 {
            margin-bottom: 5px;
        }

        .text {
            display: flex;
            flex-direction: column;
            align-items: center;

            .logo {
                font-size: 8px;
                margin-bottom: -3px;
            }
        }

        .subtitle {
            font-size: 16px;
        }
    }
}

@media screen and (max-width: 786px) {
    #home {
        header {
            padding: 0;
            justify-content: center;

            .header-img {
                background-position: right;
                opacity: 0.5!important;
            }

            .header-container {
                width: 100%;
                height: 100%;
                justify-content: center;

                .logo {
                    min-width: 100%;
                }
                .subtitle {
                    text-align: center;
                    width: 80%;
                    margin: 30px auto;
                }
            }

        }
    }

    .socialMediaFooter {
        .links {
            flex-direction: column;
        }
    }
}

@keyframes scrollDown {
    0% {
        transform: translateY(-2.5px) rotate(-135deg);
    } 
    50% {
        transform: translateY(2.5px) rotate(-135deg);
    }
    100% {
        transform: translateY(-2.5px) rotate(-135deg);
    }
}