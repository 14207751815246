#ranking {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
    
    .section-container {
        max-width: 870px;
        padding: 20px;
        padding-top: 10px;
        padding-top: 0;
        background-color: transparent;
        box-shadow: none;

        .image {
            height: 100%;
        }

        th {
            border: none;
            width: 0;
        }

        .table {
            max-width: 550px;
        }
    }


    .section-content {
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .illustrations-ranking {
        align-items: flex-end;
        justify-content: space-between;
        height: 100vh;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        pointer-events: none;

        .illustration-2 {
        }
    
        .illustration-1 {
        }
    }



    .first {
        left: 0;
    }
    .second {
        right: 0;
    }

    .arrow {
        padding: 20px;
        cursor: pointer;
    }

    .pages {
        display: flex;
        column-gap: 10px;
        align-items: center;
        justify-content: center;
        user-select: none;

        color: #ffd3ab;

        span {
            opacity: 0.5;
        }

        .page, .lastPage {
            cursor: pointer;
            opacity: 0.5;
            
            &.active {
                opacity: 1;
            }
        }
    }

    .main-divider {
        margin: 10px 0;
    }

    .cathegories {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        column-gap: 30px;
        padding: 25px;
        padding-bottom: 0;
        font-family: 'Open Sans';
        font-size: 14px;
        flex-wrap: wrap;

        .cathegorie {
            padding: 20px 10px;
            position: relative;
            cursor: pointer;
            border-radius: 10px;
            color: #ffd3ab;
            white-space: nowrap;

            &:hover {
                background-color: #1c1a19;
            }

            &.active {
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 3px;
                    width: 100%;
                    background-color: #e8991b;
                }
            }
        }
    }
}

@media screen and (max-width: 1233px) {
    #ranking {
        .section-container {
            position: relative;
            z-index: 2;
        }
    }
}

@media screen and (max-width: 768px) {
    #ranking {
        .illustrations-ranking {
            display: none;
        }
    }
}