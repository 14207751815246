footer, .socialMediaFooter {
    margin-bottom: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.flex {
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 0;
        background-color: #100e0f;
        padding: 0 120px;
        height: 65px;

        p {
            font-size: 12px;
            color: #ccb6a2;
        }
    }

    .subtitle {
        margin-bottom: 25px;
    }

    img {
        width: 30px;
        margin-right: 7px;
    }

    .flex.links {
        align-items: center;
        row-gap: 15px;
        column-gap: 15px;
    }

    .yt {
        background-color: #bc1d1d;
    }

    .fb {
        background-color: #2152c1;
    }

    .dc {
        background-color: #5566e4;
    }

    a {
        padding: 10px 10px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

}

@media screen and (max-width: 786px) {
    footer, .socialMediaFooter {
        &.flex {
            flex-direction: column;
            padding: 20px;
            text-align: center;
            row-gap: 20px;
            height: 100%;
        }

        .subtitle {
            text-align: center;
            width: 80%;
            margin: auto;
            margin-bottom: 30px;
        }
    }
}