
.demo-vertical-track {
    border: 1px solid #6e594f;
    width: 15px;
    transition: opacity 0.3s;
    margin-left: 20px;
    padding: 2px;
    z-index: 3;
}

.demo-vertical-handler {
    width: 9px;
    background-color: #6e594f;
    margin: 2px 0;
}

.demo-square {
    display: none;
}

.FreeScrollbar-container {
    scroll-behavior: auto;
}

.demo-horizontal-track {
    border: 1px solid #6e594f;
    height: 15px;
    transition: opacity 0.3s;
    margin-right: 20px;
    padding: 2px;
}

.demo-horizontal-handler {
    height: 9px;
    background-color: #6e594f;
    margin: 0 2px;
    z-index: 3;

}