@font-face {
  font-family: 'Open Sans Extra Bold';
  src: url("../assets/fonts/OpenSans/OpenSans-ExtraBold.ttf");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans Bold';
  src: url("../assets/fonts/OpenSans/OpenSans-Bold.ttf");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../assets/fonts/OpenSans/OpenSans-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Friz Quadrata';
  src: url("../assets/fonts/FrizQ/Fri-Quadrata-TT-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Friz Quadrata TT";
  src: url("//db.onlinewebfonts.com/t/8eccce155090104c7902b964cd0e12fe.eot");
  src: url("//db.onlinewebfonts.com/t/8eccce155090104c7902b964cd0e12fe.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8eccce155090104c7902b964cd0e12fe.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8eccce155090104c7902b964cd0e12fe.woff") format("woff"), url("//db.onlinewebfonts.com/t/8eccce155090104c7902b964cd0e12fe.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8eccce155090104c7902b964cd0e12fe.svg#Friz Quadrata TT") format("svg");
}

@font-face {
  font-family: 'Myriad';
  src: url("../assets/fonts/Myriad/MyriadPro-regulard.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Light';
  src: url("../assets/fonts/Myriad/MyriadPro-Light.otf");
  font-weight: 300;
  font-style: normal;
}

#home-news {
  padding: 30px 0;
  padding-top: 65px;
  position: relative;
}

#home-news h2 {
  text-align: center;
  z-index: 2;
  position: relative;
}

#home-news article {
  z-index: 3;
  position: relative;
}

#home-news .arrow {
  padding: 20px;
  cursor: pointer;
}

#home-news .pages {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  z-index: 3;
  position: relative;
  color: #ffd3ab;
}

#home-news .pages span {
  opacity: 0.5;
}

#home-news .pages .page, #home-news .pages .lastPage {
  cursor: pointer;
  opacity: 0.5;
}

#home-news .pages .page.active, #home-news .pages .lastPage.active {
  opacity: 1;
}

#newsCard {
  max-width: 640px;
  margin: 0 auto;
  font-family: 'Open Sans';
  position: relative;
  z-index: 3;
}

#newsCard .article-img {
  width: 100%;
  height: 280px;
}

#newsCard .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 15px;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

#newsCard h3 {
  margin-bottom: 10px;
}

#newsCard .flex {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#newsCard .flex .container {
  color: #787878;
}

#newsCard .date {
  color: #787878;
}

#newsCard .author {
  color: #997c6d;
}

#newsCard .divider {
  margin: 0 20px;
  height: 10px;
  width: 1px;
  background-color: #787878;
}

#newsCard .text {
  font-size: 11px;
}

#newsCard .button, #newsCard button {
  width: 75px;
  height: 25px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  #newsCard {
    max-width: 80%;
  }
}

#homeRanking {
  padding: 45px 0;
  position: relative;
}

#homeRanking h2 {
  text-align: center;
  z-index: 2;
  position: relative;
  margin-bottom: 25px !important;
}

#homeRanking .custom-button {
  position: relative;
  z-index: 2;
  margin: 0 auto;
  margin-top: 30px;
}

#homeRanking #ranking-table {
  position: relative;
  z-index: 3;
  max-width: 700px;
  height: 100%;
  width: 100%;
  margin: auto;
  padding-top: 5px;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 30px;
}

@media screen and (max-width: 768px) {
  #homeRanking #ranking-table {
    padding: 0 30px;
    padding-bottom: 40px;
  }
  #homeRanking #ranking-table .table th, #homeRanking #ranking-table .table td {
    padding: 5px 10px;
  }
}

/* Home */
#home {
  background-color: #000;
}

#home .scrollTop {
  position: fixed;
  bottom: 10%;
  right: 5%;
  border: solid 1px #918173;
  height: 45px;
  width: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 99;
  cursor: pointer;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

#home .scrollTop.show {
  opacity: 1;
}

#home .scrollTop .arrow {
  border-top: solid 2px #918173;
  border-left: solid 2px #918173;
  height: 10px;
  width: 10px;
  -webkit-transform: translateY(30%) rotate(45deg);
          transform: translateY(30%) rotate(45deg);
}

#home .scrollDown {
  position: absolute;
  bottom: 5%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border: solid 1px #918173;
  height: 50px;
  width: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 99;
  border-radius: 100px;
  cursor: pointer;
}

#home .scrollDown::after {
  content: '向下捲動';
  position: absolute;
  top: -25px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 100px;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 12px;
  color: #918173;
}

#home .scrollDown .arrow {
  border-top: solid 2px #918173;
  border-left: solid 2px #918173;
  height: 10px;
  width: 10px;
  -webkit-animation: scrollDown 1.5s ease-in-out infinite;
          animation: scrollDown 1.5s ease-in-out infinite;
}

#home h2 {
  font-size: 20px;
  margin-bottom: 45px;
}

#home header {
  height: 100vh;
  width: 100%;
  padding: 0 130px;
  padding-top: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

#home header .serverStatus {
  font-family: 'Friz Quadrata';
  font-size: 10px;
  position: absolute;
  top: 20px;
  right: 25px;
}

#home header .serverStatus span {
  color: #41b561;
}

#home header .social-icons {
  position: absolute;
  top: 200px;
  right: 0;
  z-index: 99;
}

#home header .social-icons a {
  display: block;
  height: 50px;
  width: 50px;
}

#home header .header-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

#home header .header-img::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: -webkit-gradient(linear, left bottom, left top, from(#0b0a0a), color-stop(50%, transparent));
  background: linear-gradient(to top, #0b0a0a, transparent 50%);
}

#home header .header-container {
  max-width: 500px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 75%;
  position: relative;
  z-index: 2;
  padding-top: 20px;
}

#home header .header-container .subtitle {
  font-size: 18px;
  font-family: 'Friz Quadrata';
  margin-bottom: 40px;
  max-width: 430px;
  text-align: justify;
}

#home header .header-container .logo {
  min-height: 310px;
  min-width: 400px;
  height: 30vw;
  width: 35vw;
}

#home header .header-container p {
  font-family: 'Friz Quadrata';
}

#home header .header-container button {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0;
  width: 290px;
  height: 50px;
}

#home header .header-container button img {
  margin-right: 10px;
}

#home .socialMediaFooter {
  margin-bottom: 0;
  padding-bottom: 65px;
  padding-top: 65px;
}

#home .socialMediaFooter h2 {
  margin-bottom: 5px;
}

#home .socialMediaFooter .text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#home .socialMediaFooter .text .logo {
  font-size: 8px;
  margin-bottom: -3px;
}

#home .socialMediaFooter .subtitle {
  font-size: 16px;
}

@media screen and (max-width: 786px) {
  #home header {
    padding: 0;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #home header .header-img {
    background-position: right;
    opacity: 0.5 !important;
  }
  #home header .header-container {
    width: 100%;
    height: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #home header .header-container .logo {
    min-width: 100%;
  }
  #home header .header-container .subtitle {
    text-align: center;
    width: 80%;
    margin: 30px auto;
  }
  .socialMediaFooter .links {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@-webkit-keyframes scrollDown {
  0% {
    -webkit-transform: translateY(-2.5px) rotate(-135deg);
            transform: translateY(-2.5px) rotate(-135deg);
  }
  50% {
    -webkit-transform: translateY(2.5px) rotate(-135deg);
            transform: translateY(2.5px) rotate(-135deg);
  }
  100% {
    -webkit-transform: translateY(-2.5px) rotate(-135deg);
            transform: translateY(-2.5px) rotate(-135deg);
  }
}

@keyframes scrollDown {
  0% {
    -webkit-transform: translateY(-2.5px) rotate(-135deg);
            transform: translateY(-2.5px) rotate(-135deg);
  }
  50% {
    -webkit-transform: translateY(2.5px) rotate(-135deg);
            transform: translateY(2.5px) rotate(-135deg);
  }
  100% {
    -webkit-transform: translateY(-2.5px) rotate(-135deg);
            transform: translateY(-2.5px) rotate(-135deg);
  }
}

/***** COMPONENTS CSS *****/
.form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
}

.form h1 {
  text-align: center;
  font-family: 'Friz Quadrata';
  text-transform: uppercase;
  font-size: 20px;
}

.form .inputs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 20px;
}

.form .spacer {
  height: 1px;
  width: 100%;
  background-color: #6e594f;
  opacity: 0.5;
  margin-top: 15px;
  margin-bottom: 35px;
}

.form .info {
  color: #6e594f;
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
  font-family: 'Open Sans';
  max-width: 300px;
}

.form .form-container {
  padding-top: 25px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  background-color: #171315;
  border-radius: 10px;
}

.form .button, .form button {
  margin: auto;
  margin-top: 45px;
  margin-bottom: 23px;
}

.input label {
  margin-bottom: 5px;
  display: block;
  font-size: 14px;
  font-family: 'Open Sans';
}

.input .input-container {
  border: solid 1px #6e594f;
  background-color: #131011;
  padding: 10px;
  height: 40px;
  width: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.input .divider {
  margin: 0 6px;
  height: 12px;
  width: 1px;
  background-color: #1f1a1c;
}

.input input {
  border: none;
  background-color: #131011;
  outline: none;
  color: #fff;
  max-height: 100%;
  width: 100%;
  font-size: 14px;
}

.input input::-webkit-input-placeholder {
  color: #1f1a1c;
}

.input input:-ms-input-placeholder {
  color: #1f1a1c;
}

.input input::-ms-input-placeholder {
  color: #1f1a1c;
}

.input input::placeholder {
  color: #1f1a1c;
}

.input input:-webkit-autofill,
.input input:-webkit-autofill:hover,
.input input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #131011 inset;
  border: none;
  -webkit-text-fill-color: #fff;
  font-weight: 400;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

@media screen and (max-width: 768px) {
  .input .input-container {
    width: 100%;
  }
}

.custom-button {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  color: #492619 !important;
  font-family: 'Friz Quadrata';
  font-weight: 700;
  letter-spacing: -0.8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: none;
  -webkit-box-shadow: 0px 3px 7px 0px rgba(5, 0, 6, 0.35);
          box-shadow: 0px 3px 7px 0px rgba(5, 0, 6, 0.35);
  text-shadow: 0px 3px 7px rgba(5, 0, 6, 0.35);
  white-space: nowrap;
  position: relative;
}

.custom-button::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  top: 0;
  left: 0;
}

.custom-button.disable {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  cursor: not-allowed;
}

.custom-button:hover {
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
  -webkit-box-shadow: 0 0 10px 0 #fff inset;
          box-shadow: 0 0 10px 0 #fff inset;
}

.custom-button:hover::after {
  -webkit-box-shadow: 0 0 10px 0 #fff;
          box-shadow: 0 0 10px 0 #fff;
}

.custom-button:hover.disable {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  -webkit-box-shadow: 0 0 0 0 #fff inset;
          box-shadow: 0 0 0 0 #fff inset;
}

.custom-button:hover.disable::after {
  -webkit-box-shadow: 0 0 0 0 #fff;
          box-shadow: 0 0 0 0 #fff;
}

.select {
  position: relative;
  text-align: center;
  border: solid 1px #e8991b;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 20px auto;
  background-color: #0f0c0e;
}

.select .selected {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}

.select .arrow {
  width: 25px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.select.active .arrow {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.select .img-container {
  height: 100%;
  width: 50px;
  margin-left: 20px;
  background-color: #ffab48;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 10px 0;
}

.select .options-container {
  -webkit-transition: all 0.5s cubic-bezier(0.43, 0.13, 0.23, 0.96);
  transition: all 0.5s cubic-bezier(0.43, 0.13, 0.23, 0.96);
  z-index: 99;
  position: relative;
}

.select .options {
  background-color: #1d1a1b;
  padding: 15px 8px;
  position: absolute;
  top: 150%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 5px;
  overflow: hidden;
  z-index: 99;
}

.select .options .option {
  padding: 7px 30px !important;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}

.select .options .option.active {
  color: #ffab48;
}

.select .options .option:hover {
  background-color: #171313;
}

nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  padding: 50px 150px;
  z-index: 9999;
}

nav::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 100vw;
  -webkit-backdrop-filter: blur(0);
          backdrop-filter: blur(0);
  z-index: -1;
  -webkit-transition: height 0s 0.5s, -webkit-backdrop-filter 0.5s;
  transition: height 0s 0.5s, -webkit-backdrop-filter 0.5s;
  transition: height 0s 0.5s, backdrop-filter 0.5s;
  transition: height 0s 0.5s, backdrop-filter 0.5s, -webkit-backdrop-filter 0.5s;
}

nav.showSecondNav::after {
  -webkit-transition: -webkit-backdrop-filter 0.5s;
  transition: -webkit-backdrop-filter 0.5s;
  transition: backdrop-filter 0.5s;
  transition: backdrop-filter 0.5s, -webkit-backdrop-filter 0.5s;
  height: 100vh;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

nav .user {
  position: relative;
}

nav .user .secondNav {
  background-color: #0a0808;
  padding: 15px 0;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  right: 0;
  -webkit-transform: translate(40%, 105%);
          transform: translate(40%, 105%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 165px;
}

nav .user .secondNav .main-divider {
  opacity: 0.5;
  background-color: #fff;
  height: 1px;
  width: 80%;
  margin: 10px auto;
}

nav .user .secondNav button {
  background-color: transparent;
  color: #f6b66b;
  border: none;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 100;
  font-family: 'Friz Quadrata';
}

nav .user .secondNav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 7px;
}

nav .user .secondNav ul li {
  width: 100%;
}

nav .user .secondNav ul li.active {
  color: #ffab48;
}

nav .user .secondNav ul li a {
  font-family: 'Friz Quadrata';
  width: 100%;
  text-align: center;
  display: block;
  white-space: nowrap;
  font-size: 12px;
  padding: 6px;
}

nav .user .secondNav ul li a:hover {
  background-color: #171313;
}

nav .user .info {
  margin: 0 !important;
}

nav .user .flex {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

nav .user .flex .flex {
  -webkit-column-gap: 5px;
          column-gap: 5px;
  cursor: pointer;
}

nav .user .chevron {
  height: 7px;
  width: 7px;
  border-right: 1.5px solid #ffab48;
  border-bottom: 1.5px solid #ffab48;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

nav .user .chevron.active {
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
}

nav .user .welcome {
  white-space: nowrap;
  font-size: 16px;
  font-family: 'Friz Quadrata';
}

nav .user .welcome .info {
  font-size: 16px;
}

nav .user .pp {
  min-height: 45px;
  min-width: 45px;
  border-radius: 100px;
}

nav .nav-container {
  width: 100%;
  margin-right: 30px;
}

nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  max-width: 455px;
  width: 100%;
}

nav ul a {
  font-family: 'Friz Quadrata';
  font-size: 16px;
}

nav ul li.active a {
  color: #f6b66b;
}

nav ul span {
  height: 10px;
  width: 1px;
  background-color: #443f43;
  display: block;
}

nav .auth, nav .auth-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

nav .auth .button-light, nav .auth-nav .button-light {
  white-space: nowrap;
  color: #f6921e;
  font-family: 'Friz Quadrata';
  font-size: 16px;
}

nav .auth span, nav .auth-nav span {
  margin: 0 25px;
  font-size: 12px;
  font-family: 'Friz Quadrata';
}

nav .auth-nav {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 30px;
  margin-top: 50px;
}

nav .auth-nav .button-light {
  font-size: 25px;
}

nav .auth-nav .button, nav .auth-nav button {
  font-size: 25px;
}

@media screen and (min-width: 787px) {
  nav .menu-icon {
    display: none;
  }
  nav .auth-nav {
    display: none !important;
  }
}

@media screen and (max-width: 786px) {
  nav {
    padding: 30px 30px;
    position: fixed;
  }
  nav .menu-icon-container {
    height: 50px;
    width: 50px;
    position: relative;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    cursor: pointer;
  }
  nav .menu-icon {
    width: 45px;
    height: 2px;
    background-color: #fff;
    z-index: 2;
    position: relative;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
  }
  nav .menu-icon::after {
    content: '';
    height: 2px;
    width: 45px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
  }
  nav .menu-icon::before {
    content: '';
    height: 2px;
    width: 45px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    -webkit-transform: translateY(12px);
            transform: translateY(12px);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
  }
  nav .menu-icon.active {
    background-color: transparent;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  nav .menu-icon.active::after {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  nav .menu-icon.active::before {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  nav .nav-container {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #171313;
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.83, 0, 0.16, 0.99);
    transition: -webkit-transform 1s cubic-bezier(0.83, 0, 0.16, 0.99);
    transition: transform 1s cubic-bezier(0.83, 0, 0.16, 0.99);
    transition: transform 1s cubic-bezier(0.83, 0, 0.16, 0.99), -webkit-transform 1s cubic-bezier(0.83, 0, 0.16, 0.99);
  }
  nav .nav-container ul {
    width: 100%;
    height: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    padding: 20vh 0;
    margin: auto;
  }
  nav .nav-container ul li a {
    font-size: 30px;
  }
  nav .nav-container span {
    display: none;
  }
  nav .nav-container.show {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  nav .user .welcome {
    display: none;
  }
  nav .user .secondNav {
    -webkit-transform: translate(10%, 105%);
            transform: translate(10%, 105%);
  }
  nav ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  nav .auth {
    display: none;
  }
}

footer, .socialMediaFooter {
  margin-bottom: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

footer.flex, .socialMediaFooter.flex {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 0;
  background-color: #100e0f;
  padding: 0 120px;
  height: 65px;
}

footer.flex p, .socialMediaFooter.flex p {
  font-size: 12px;
  color: #ccb6a2;
}

footer .subtitle, .socialMediaFooter .subtitle {
  margin-bottom: 25px;
}

footer img, .socialMediaFooter img {
  width: 30px;
  margin-right: 7px;
}

footer .flex.links, .socialMediaFooter .flex.links {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
}

footer .yt, .socialMediaFooter .yt {
  background-color: #bc1d1d;
}

footer .fb, .socialMediaFooter .fb {
  background-color: #2152c1;
}

footer .dc, .socialMediaFooter .dc {
  background-color: #5566e4;
}

footer a, .socialMediaFooter a {
  padding: 10px 10px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

@media screen and (max-width: 786px) {
  footer.flex, .socialMediaFooter.flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 20px;
    text-align: center;
    row-gap: 20px;
    height: 100%;
  }
  footer .subtitle, .socialMediaFooter .subtitle {
    text-align: center;
    width: 80%;
    margin: auto;
    margin-bottom: 30px;
  }
}

/* SHOP */
.items-card {
  width: 205px;
  border-radius: 5px;
  margin: 7px 5px;
  font-family: 'Open Sans', sans-serif;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.items-card::before {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  width: 207px;
  height: 237px;
  border-radius: 5px;
  background: -webkit-gradient(linear, left top, left bottom, from(#cf914b), color-stop(70%, #171315), to(#cf914b7c));
  background: linear-gradient(180deg, #cf914b 0%, #171315 70%, #cf914b7c 100%);
}

.items-card:hover {
  -webkit-box-shadow: 0 0 50px #000;
          box-shadow: 0 0 50px #000;
}

.items-card .header {
  height: 118px;
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.items-card .header .img-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 50px;
  width: 50px;
  border: solid 1px #f6b66b;
  background-color: #0907081f;
  padding: 5px;
}

.items-card .header .img-container .img-item {
  height: 100%;
  width: 100%;
}

.items-card .header h4 {
  font-size: 13.3px;
  margin-bottom: 7px;
}

.items-card .divider {
  height: 3.55px;
  width: 100%;
  background-color: #ffab48;
  position: relative;
  z-index: 2;
}

.items-card .description {
  height: 113px;
  width: 100%;
  padding: 10px;
  padding-top: 2px;
  padding-bottom: 0;
  background-color: #1c1515;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  z-index: 2;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.items-card .description h5 {
  color: #ffab48;
  text-align: center;
  font-size: 9.25px;
  margin-bottom: 5px;
}

.items-card .description p {
  font-size: 10px;
  letter-spacing: -0.5px;
  margin-bottom: 8px;
  line-height: 12px;
}

.items-card .description span {
  color: #ffab48;
  font-weight: 700;
  font-size: 14px;
  white-space: nowrap;
  font-family: 'Open Sans Bold';
}

.items-card .description .flex {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.items-card .description img {
  height: 25px;
  margin-right: 3px;
}

.items-card .description .custom-button {
  margin-left: 10px;
}

#filters .section-container {
  padding: 0;
}

#filters .filter-container {
  margin-bottom: 20px;
  width: 780px;
  height: 88px;
  font-family: 'Open sans';
  font-size: 14px;
  padding: 0 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#filters .filter-container .select {
  border: none;
  background-color: transparent;
  margin: 0;
}

#filters .filter-container .select .selected {
  margin: 0;
}

#filters .flex {
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  list-style: none;
}

#filters .flex .selected::after {
  content: '';
  position: absolute;
  height: 6px;
  width: 6px;
  border-left: 1.5px solid #fff;
  border-bottom: 1.5px solid #fff;
  right: -15px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

#filters .flex .activeMenu .active .selected::after {
  -webkit-transform: rotate(135deg) translateY(-50%);
          transform: rotate(135deg) translateY(-50%);
}

#filters .flex .activeMenu .selected {
  color: #ffab48;
  position: relative;
}

#filters .flex .activeMenu .selected::after {
  border-left: 1.5px solid #ffab48;
  border-bottom: 1.5px solid #ffab48;
}

#filters .flex li {
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

#filters .flex li:hover {
  color: #ffab48;
}

#shop .shop-container {
  position: relative;
  max-height: 100vh;
  padding: 0 50px;
}

#shop .FreeScrollbar-container {
  inset: 0px -17px 0 0px !important;
  overflow-x: hidden !important;
}

#shop .demo::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(calc(-50% - 16px));
          transform: translateX(calc(-50% - 16px));
  height: 200px;
  max-width: 1100px;
  width: calc(100% - 20px);
  background: -webkit-gradient(linear, left top, left bottom, from(#17131500), to(#171315));
  background: linear-gradient(#17131500, #171315);
  pointer-events: none;
  z-index: 3;
}

#shop #filters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  max-width: 780px;
  margin: auto;
}

#shop .items {
  position: relative;
}

#shop .items .items-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  max-width: 1155px;
  margin: auto;
  padding-right: 50px;
  max-height: 70vh;
}

@media screen and (max-width: 768px) {
  #filters .filter-container {
    padding-top: 30px;
    padding-bottom: 30px;
    height: 100%;
  }
  #filters .filter-container .select {
    margin: auto;
  }
  #filters .filter-container .select .selected {
    padding: 0;
  }
  #filters .filter-container ul {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
    row-gap: 30px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

@media screen and (max-width: 568px) {
  #shop .shop-container {
    padding: 0;
    max-height: initial !important;
  }
  #shop .shop-container .items {
    position: relative;
  }
  #shop .shop-container .items .items-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    max-height: initial !important;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow: hidden;
  }
}

/* Download */
#download .downloads {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  row-gap: 12px;
  margin-bottom: 40px;
}

#download h4 {
  margin-bottom: 8px;
  font-family: 'Friz Quadrata';
  font-weight: 400;
  font-size: 16px;
}

#download h5 {
  margin-bottom: 15px;
  font-weight: 400;
  text-align: center;
  font-size: 16px;
  font-family: 'Friz Quadrata';
}

#download .custom-button {
  width: 290px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
  padding-left: 50px;
  font-family: 'Friz Quadrata';
  color: #492619;
  font-size: 17px;
  text-align: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

#download .custom-button.disable img {
  margin-right: 25px;
}

#download .custom-button img {
  margin-right: 10px;
}

#download .paragraphs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-column-gap: 90px;
          column-gap: 90px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 10px;
}

#download .paragraphs .section-container {
  margin: 0;
  max-width: 300px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  #download .paragraphs {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  #download .paragraphs .section-container {
    margin: 0 !important;
    margin-top: 20px !important;
    width: 100% !important;
  }
  #download .paragraphs .mt {
    margin-top: 20px;
  }
}

.steps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-family: 'Open Sans', sans-serif;
}

.steps .section-container {
  padding: 13px 35px;
  -webkit-column-gap: 40px;
          column-gap: 40px;
  row-gap: 40px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 45px;
}

.steps .step {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.steps .step .step-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.steps .step .num {
  font-family: 'Open Sans Extra Bold';
  font-size: 80px;
  margin-right: 10px;
  line-height: 80px;
}

.steps .step .text {
  max-width: 160px;
}

.steps .step .text .title {
  color: #f6b66b;
  font-size: 12px;
  font-family: 'Open Sans Bold';
}

.steps .step .text .description {
  font-family: 'Open Sans';
  font-size: 10px;
}

.steps .divider {
  height: 1px;
  width: 45px;
  background-color: #2e262a;
}

@media screen and (max-width: 900px) {
  .steps .steps-container {
    padding: 20px 40px;
  }
}

@media screen and (max-width: 768px) {
  .steps .steps-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .steps .step {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .steps .step .divider {
    height: 70px;
    width: 1px;
  }
}

/* Buy Coins */
#buyCoins {
  padding-top: 150px;
  padding-bottom: 100px;
}

#buyCoins h1 {
  font-family: 'Open Sans';
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  z-index: 2;
  position: relative;
  margin-bottom: 20px;
}

#buyCoins .paymentMethodes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 0 10vw;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

#buyCoins .paymentMethodes .paymentMethode {
  padding: 30px 50px;
  background-color: #171315;
  height: 120px;
  width: 310px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

#buyCoins .paymentMethodes .paymentMethode.active {
  -webkit-box-shadow: inset 0 0 10px #d79f5e;
          box-shadow: inset 0 0 10px #d79f5e;
  background-color: #221d1f;
}

#buyCoins .paymentMethodes .paymentMethode.active::before {
  -webkit-box-shadow: 0 0 10px #d79f5e !important;
          box-shadow: 0 0 10px #d79f5e !important;
}

#buyCoins .paymentMethodes .paymentMethode::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

#buyCoins .paymentMethodes .paymentMethode:hover::before {
  -webkit-box-shadow: 0 0 50px #000;
          box-shadow: 0 0 50px #000;
}

#buyCoins .paymentMethodes .paymentMethode img {
  height: 40px;
}

#buyCoins .paymentMethodes .mx img {
  height: 30px;
}

#buyCoins .coinsPrices {
  width: 100%;
}

#buyCoins .coinPrice {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border: solid 1px #ffab48;
  border-radius: 5px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 5px 0;
  width: 100%;
  cursor: pointer;
  background-color: #0f0c0e;
}

#buyCoins .coinPrice img {
  height: 20px;
  margin-right: 10px;
}

#buyCoins .coinPrice .flex {
  margin: 0 10px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

#buyCoins .coinPrice p {
  font-family: 'Open Sans';
  font-size: 12px;
}

#buyCoins .coinPrice .price {
  background-color: #ffab48;
  padding: 10px 25px;
  color: #000;
  font-weight: 700;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  max-width: 170px;
  font-family: 'Open Sans Extra Bold';
  font-size: 12px;
  font-weight: 600;
}

#buyCoins .coinbase .paymentMethod-img {
  height: 25px !important;
}

#buyCoins .stripe, #buyCoins .coinbase, #buyCoins .paysafecard {
  width: 100%;
  margin: 30px auto;
  max-width: 555px;
  padding: 25px;
  border-radius: 15px;
  font-family: 'Open sans', sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#buyCoins .stripe .price-container, #buyCoins .coinbase .price-container, #buyCoins .paysafecard .price-container {
  max-width: 430px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 14px;
}

#buyCoins .stripe .price-container .code, #buyCoins .coinbase .price-container .code, #buyCoins .paysafecard .price-container .code {
  background-color: #0f0c0e;
  border: solid 1px #ffab48;
  border-radius: 10px;
  padding: 15px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 345px;
  width: 100%;
}

#buyCoins .stripe .price-container .code input, #buyCoins .coinbase .price-container .code input, #buyCoins .paysafecard .price-container .code input {
  border: none;
  background-color: #0f0c0e;
  outline: none;
  color: #fff;
  max-height: 100%;
  width: 100%;
  font-size: 14px;
  margin-left: 15px;
  font-family: 'Open Sans';
}

#buyCoins .stripe .price-container .code input:-webkit-autofill,
#buyCoins .stripe .price-container .code input:-webkit-autofill:hover,
#buyCoins .stripe .price-container .code input:-webkit-autofill:focus, #buyCoins .coinbase .price-container .code input:-webkit-autofill,
#buyCoins .coinbase .price-container .code input:-webkit-autofill:hover,
#buyCoins .coinbase .price-container .code input:-webkit-autofill:focus, #buyCoins .paysafecard .price-container .code input:-webkit-autofill,
#buyCoins .paysafecard .price-container .code input:-webkit-autofill:hover,
#buyCoins .paysafecard .price-container .code input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #0f0c0e inset;
  border: none;
  -webkit-text-fill-color: #fff;
  font-weight: 400;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

#buyCoins .stripe .paymentMethod-img, #buyCoins .coinbase .paymentMethod-img, #buyCoins .paysafecard .paymentMethod-img {
  height: 35px;
}

#buyCoins .stripe .main-divider, #buyCoins .coinbase .main-divider, #buyCoins .paysafecard .main-divider {
  margin: 10px 0;
}

#buyCoins .stripe .info, #buyCoins .coinbase .info, #buyCoins .paysafecard .info {
  font-size: 12px;
  max-width: 500px;
  text-align: center;
}

#buyCoins .stripe .term, #buyCoins .coinbase .term, #buyCoins .paysafecard .term {
  max-width: 220px;
  font-size: 12px;
  text-align: center;
  font-family: 'Open Sans';
}

#buyCoins .stripe button, #buyCoins .stripe .button, #buyCoins .coinbase button, #buyCoins .coinbase .button, #buyCoins .paysafecard button, #buyCoins .paysafecard .button {
  width: 140px;
  height: 40px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 16px;
}

#buyCoins .select {
  border: solid 1px #e8991b;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 345px;
  margin: 0;
  width: 100%;
}

#buyCoins .select .options {
  font-size: 13px;
}

#buyCoins .select .options .option {
  padding: 15px;
}

#buyCoins .select .selected {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: 'Open Sans';
  font-size: 13px;
}

#buyCoins .select .gold-img {
  height: 20px;
  width: 20px;
  margin-right: 20px;
  margin-left: 15px;
}

@media screen and (max-width: 788px) {
  #buyCoins .paymentMethodes {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #buyCoins .paymentMethodes .mx {
    margin: 30px 0;
  }
}

/* Daily Rewards */
#dailyRewards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}

#dailyRewards h1 {
  font-weight: 100;
  margin: 0;
}

#dailyRewards .section-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#dailyRewards .section-container {
  padding-top: 25px;
  padding-left: 45px;
  padding-right: 45px;
  padding-bottom: 0;
  max-width: 625px;
  width: 100%;
  margin: 0;
}

#dailyRewards .rewards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  max-width: 500px;
  -webkit-column-gap: 25px;
          column-gap: 25px;
  row-gap: 65px;
  margin-top: 35px;
  margin-bottom: 60px;
}

#dailyRewards .rewards .reward {
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 50px;
  height: 50px;
  background: #171315;
}

#dailyRewards .rewards .reward::before {
  content: '';
  position: absolute;
  width: 52px;
  height: 52px;
  background: -webkit-gradient(linear, left top, left bottom, from(#876540), color-stop(50%, #171315), to(#876540));
  background: linear-gradient(180deg, #876540 0%, #171315 50%, #876540 100%);
}

#dailyRewards .rewards .reward::after {
  content: '';
  position: absolute;
  width: 50px;
  height: 50px;
  background: #171315;
}

#dailyRewards .rewards .reward img {
  position: relative;
  z-index: 2;
}

#dailyRewards .rewards .reward.win {
  border: solid 1px #876540;
  -webkit-animation: rewardsWin 3s ease-in-out infinite;
          animation: rewardsWin 3s ease-in-out infinite;
  cursor: pointer;
  position: relative;
}

#dailyRewards .rewards .reward.win::before {
  background: transparent;
}

#dailyRewards .rewards .reward.win::after {
  content: 'YOU WIN  THIS';
  position: absolute;
  bottom: -100%;
  left: 50%;
  color: #a7885a;
  width: 90px;
  height: auto;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-family: 'Friz Quadrata';
  font-size: 16px;
  line-height: 18px;
  background: transparent;
}

#dailyRewards .info {
  max-width: 500px;
  font-size: 18px;
  font-family: 'Open Sans';
  line-height: 18px;
}

@-webkit-keyframes rewardsWin {
  0% {
    -webkit-box-shadow: 0 0 10px #f6b56b;
            box-shadow: 0 0 10px #f6b56b;
  }
  50% {
    -webkit-box-shadow: 0 0 30px #f6b56b;
            box-shadow: 0 0 30px #f6b56b;
  }
  100% {
    -webkit-box-shadow: 0 0 10px #f6b56b;
            box-shadow: 0 0 10px #f6b56b;
  }
}

@keyframes rewardsWin {
  0% {
    -webkit-box-shadow: 0 0 10px #f6b56b;
            box-shadow: 0 0 10px #f6b56b;
  }
  50% {
    -webkit-box-shadow: 0 0 30px #f6b56b;
            box-shadow: 0 0 30px #f6b56b;
  }
  100% {
    -webkit-box-shadow: 0 0 10px #f6b56b;
            box-shadow: 0 0 10px #f6b56b;
  }
}

/* News */
#news .section {
  padding-bottom: 0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  padding: 0;
}

#news .section .section-content {
  height: 100%;
  width: 100%;
}

#news .section .lastNews-img {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 500px;
  width: 500px;
  z-index: 1;
  opacity: 0.5;
}

#news .section .button, #news .section button {
  z-index: 30;
  position: relative;
}

#news .section .lastNews {
  position: relative;
  z-index: 3;
  padding-bottom: 165px;
  padding-left: 100px;
}

#news .section h1 {
  font-family: 'Myriad';
  font-size: 20px;
  font-weight: 100;
  margin-bottom: 30px;
}

#news .section h2 {
  font-family: 'Myriad';
  font-size: 38px;
  font-weight: 300;
  margin-bottom: 20px;
}

#news .section .author {
  color: #997c6d;
}

#news .section .author span {
  color: #838383;
}

#news .section .paragraph {
  max-width: 600px;
  text-align: justify;
  margin-bottom: 30px;
  font-family: 'Open Sans';
  font-size: 12px;
}

#news .section .header {
  margin-bottom: 10px;
}

#news .section .divider, #news .section .date {
  color: #838383;
}

#news .section .divider {
  margin: 0 10px;
}

#news .big-divider {
  width: 100%;
  height: 10px;
  background-color: #c08e55;
  -webkit-box-shadow: 0 0 500px 100px #000;
          box-shadow: 0 0 500px 100px #000;
  z-index: 2;
  position: relative;
}

#news .articles {
  margin: 50px 0;
  padding-left: 20px;
  height: 380px;
  width: 100vw;
  z-index: 4;
  position: relative;
}

#news .articles .demo-vertical-track {
  display: none;
}

#news .articles .articles-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 30px;
          column-gap: 30px;
}

#news .articles .articleCard {
  height: 350px;
  width: 300px;
  background-color: #201a1d;
  border-radius: 10px;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
}

#news .articles .articleCard:hover {
  -webkit-box-shadow: 0 0 50px #c08e55ac;
          box-shadow: 0 0 50px #c08e55ac;
}

#news .articles .articleCard .image {
  height: 60%;
  width: 100%;
}

#news .articles .articleCard .text {
  padding: 20px;
  padding-top: 20px;
}

#news .articles .articleCard .text .flex {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 200;
}

#news .articles .articleCard .text .divider {
  margin: 0 10px;
  opacity: 0.5;
}

#news .articles .articleCard .text .author {
  opacity: 0.5;
}

#news .articles .articleCard .text .author span {
  opacity: 1;
  color: #c08e55;
}

#news .articles .articleCard .text .description {
  margin-top: 10px;
  font-size: 15px;
}

@media screen and (max-width: 768px) {
  #news .big-divider {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  #news .section {
    min-height: 550px;
  }
  #news .section .lastNews-img {
    height: 250px;
    width: 250px;
    opacity: 0.5 !important;
  }
  #news .section .lastNews {
    padding-bottom: 50px;
    padding: 50px 30px;
  }
  #news .section h2 {
    font-size: 25px;
  }
  #news .section .paragraph {
    font-size: 13px;
  }
}

#article {
  font-family: 'Open Sans';
  padding: 0;
  padding-bottom: 65px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#article .section-bg-img {
  max-height: 100vh;
}

#article .illustrations-news {
  height: 100vh;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  position: absolute;
  top: 0;
  left: 0;
}

#article .illustrations-news .illustration-news-1 {
  height: 440px;
  width: 350px;
}

#article .illustrations-news .illustration-news-2 {
  height: 440px;
  width: 300px;
}

#article .section-container {
  margin-top: 200px;
  max-width: 700px;
  padding-top: 35px;
  padding-left: 55px;
  padding-right: 55px;
  padding-bottom: 30px;
  position: relative;
  z-index: 2;
}

#article .section-container h1 {
  font-size: 38px;
  font-family: 'Open Sans';
  margin: 0;
}

#article .section-container .authorDate {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#article .section-container .author {
  color: #997c6d;
}

#article .section-container .author span {
  color: #838383;
}

#article .section-container .date {
  color: #838383;
}

#article .section-container .flex {
  margin: 10px 0;
}

#article .section-container .divider {
  margin: 0 20px;
  width: 1px;
  height: 15px;
  background-color: #838383;
}

#article .section-container img {
  width: 100%;
  margin: 30px 0;
}

#article .section-container .paragraphs {
  max-width: 600px;
  font-size: 13.5px;
}

#article .section-container button, #article .section-container .button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto !important;
  margin-top: 120px !important;
}

#article .section {
  min-height: 75vh;
}

#article .big-divider {
  position: absolute;
  left: 0;
  top: 99vh;
  width: 100%;
  height: 10px;
  background-color: #c08e55;
}

@media screen and (max-width: 768px) {
  #article .illustrations-news {
    display: none;
  }
  #article .section-container {
    margin: 50px auto;
    margin-top: 200px;
    width: 95%;
  }
}

/* Whell */
#whell {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow-x: hidden;
  padding-bottom: 0;
  padding-top: 0;
}

#whell .whell-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 50px;
          column-gap: 50px;
  min-height: 100vh;
  padding-top: 100px;
}

#whell h1 {
  text-align: center;
  margin: 0;
}

#whell .main-divider {
  margin-top: 12px;
  margin-bottom: 30px;
}

#whell .section-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-column-gap: 50px;
          column-gap: 50px;
  min-height: 100vh;
}

#whell .select {
  margin-top: 8px;
  margin-bottom: 15px;
  width: 90%;
}

#whell .section-container {
  margin: 0;
  padding-top: 25px;
  max-width: 350px;
  width: 100%;
}

#whell .selected {
  padding-left: 20px;
}

#whell p {
  text-align: center;
  font-family: 'Open Sans';
  font-size: 14px;
  color: #ffab48;
}

#whell p .warning {
  font-family: 'Open Sans Extra Bold';
  font-size: 18px;
  display: inline-block;
}

@media screen and (max-width: 1180px) {
  #whell {
    padding-bottom: 100px;
  }
  #whell .whell-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    row-gap: 100px;
    padding-top: 150px;
  }
  #whell .illustration {
    position: relative;
    bottom: -100px;
  }
}

@media screen and (max-width: 768px) {
  #whell .whell-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    row-gap: 100px;
  }
  #whell .illustration {
    display: none;
  }
}

#roue {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 500px;
  max-height: 600px;
  -webkit-transform: translateY(25px);
          transform: translateY(25px);
}

#roue .dragon {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-75%);
          transform: translateX(-50%) translateY(-75%);
  z-index: 99;
}

#roue .selectedImg {
  position: absolute;
  top: 0;
}

#roue .spinner-container {
  position: absolute;
  z-index: 3;
  cursor: pointer;
}

#roue .roue-shadow-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 1000px;
}

#roue .well-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  max-width: 500px;
  max-height: 500px;
  -webkit-transition: all 5s ease-in-out;
  transition: all 5s ease-in-out;
}

#roue .items {
  position: relative;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all 5s ease-in-out;
  transition: all 5s ease-in-out;
  max-width: 500px;
  max-height: 500px;
}

#roue .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  -webkit-transition: all 5s ease-in-out;
  transition: all 5s ease-in-out;
}

#roue .item img {
  cursor: pointer;
  height: 25px;
  width: 25px;
  -webkit-transition: all 2s ease-in-out;
  transition: all 2s ease-in-out;
  z-index: -1;
}

#roue .item img.won {
  -webkit-filter: drop-shadow(0 0 5px #ff9d00);
          filter: drop-shadow(0 0 5px #ff9d00);
  -webkit-animation: itemWin 3s infinite;
          animation: itemWin 3s infinite;
}

.itemWin {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.162);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  height: 100vh;
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-animation: showItemWin 0.5s;
          animation: showItemWin 0.5s;
}

.itemWin .section-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.itemWin .section-container h2 {
  font-family: 'Myriad', sans-serif;
}

.itemWin .section-container .main-divider {
  margin-bottom: 50px;
  margin-top: 15px;
}

.itemWin .section-container img {
  margin-bottom: 10px;
  -webkit-filter: drop-shadow(0 0 5px #ff9d00);
          filter: drop-shadow(0 0 5px #ff9d00);
  -webkit-animation: itemWin 3s infinite;
          animation: itemWin 3s infinite;
}

.itemWin .section-container p {
  margin-bottom: 30px;
  color: #ff9d00;
  font-family: 'Open Sans', sans-serif;
}

.information {
  background-color: #1c1515;
  padding: 7px 5px;
  border-radius: 5px;
  border: solid 1px #e8991b;
  font-size: 9.59px;
  position: absolute;
  -webkit-transform: translate(-60%, -150%);
          transform: translate(-60%, -150%);
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 999999;
}

.information span {
  white-space: nowrap;
  font-family: 'Open Sans', sans-serif;
}

.information::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 55%) rotate(45deg);
          transform: translate(-50%, 55%) rotate(45deg);
  border-bottom: solid 1px #e8991b;
  border-right: solid 1px #e8991b;
  background-color: #1c1515;
  height: 15px;
  width: 15px;
  border-end-end-radius: 3px;
  z-index: 99;
}

@-webkit-keyframes showItemWin {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes showItemWin {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes itemWin {
  0% {
    -webkit-filter: drop-shadow(0 0 5px #ff9d00);
            filter: drop-shadow(0 0 5px #ff9d00);
  }
  50% {
    -webkit-filter: drop-shadow(0 0 0px #ff9d00);
            filter: drop-shadow(0 0 0px #ff9d00);
  }
}

@keyframes itemWin {
  0% {
    -webkit-filter: drop-shadow(0 0 5px #ff9d00);
            filter: drop-shadow(0 0 5px #ff9d00);
  }
  50% {
    -webkit-filter: drop-shadow(0 0 0px #ff9d00);
            filter: drop-shadow(0 0 0px #ff9d00);
  }
}

/* User Panel */
#userPanel {
  max-width: 100vw;
  overflow-x: hidden;
}

#userPanel .section-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 22px;
          column-gap: 22px;
  row-gap: 22px;
}

#userPanel .main-divider {
  height: 2px;
}

#userPanel .change-user-info {
  max-width: 765px;
  margin: auto;
}

#userPanel .left, #userPanel .right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 15px;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

#userPanel h2 {
  font-family: "Frizz" !important;
}

#userPanel .header {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#userPanel .change-user-info {
  padding-top: 12px;
  padding-bottom: 22px;
}

#userPanel .change-user-info h2 {
  margin: 0;
}

#userPanel .change-user-info .main-divider {
  margin-top: 8px;
  margin-bottom: 15px;
}

#userPanel .inputs {
  -webkit-column-gap: 20px;
          column-gap: 20px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

#userPanel .inputs label {
  text-transform: capitalize;
}

#userPanel .inputs .input-container {
  width: 220px;
}

@media screen and (max-width: 768px) {
  #userPanel .section-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #userPanel .header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    row-gap: 20px;
    margin-bottom: 20px;
  }
  #userPanel .inputs {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    row-gap: 20px;
  }
  #userPanel .change-user-info {
    width: 100%;
  }
}

.lockReminder {
  height: 100%;
  width: 100%;
  max-width: 315px;
}

.lockReminder .info {
  color: #f6b66b;
}

.lockReminder.section-container {
  margin: auto;
  width: 100%;
}

.lockReminder.section-container h2 {
  margin: 0;
  margin-left: 10px;
}

.lockReminder.section-container .main-divider {
  margin-bottom: 25px;
  margin-top: 15px;
}

.shopHistory {
  position: relative;
  max-width: 765px;
  margin: auto;
}

.shopHistory.section-container {
  margin: 0;
  width: 100%;
}

.shopHistory.section-container .main-divider {
  margin-bottom: -2px;
  z-index: 3;
  position: relative;
  background-color: #171315;
  padding-top: 20px;
  margin-top: 0;
  border-bottom: 2px solid #2e262a;
  width: calc(100% - 25px);
}

.shopHistory::after {
  content: '';
  position: absolute;
  bottom: 29px;
  left: 0;
  height: 100px;
  width: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(#17131500), to(#171315));
  background: linear-gradient(#17131500, #171315);
  pointer-events: none;
}

.shopHistory .table-container {
  height: 100%;
  padding-right: 20px;
  position: relative;
}

.shopHistory .table-container thead {
  position: -webkit-sticky;
  position: sticky;
  top: -10px;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #171315), to(#17131500));
  background: linear-gradient(#171315 50%, #17131500);
  border: none;
}

.shopHistory .table-container thead th {
  color: #f6b66b;
  border: none;
  text-align: center;
}

.shopHistory .table-container .table td {
  text-align: center;
}

.shopHistory .table-container .table .id {
  text-align: left !important;
}

.unbugCharacters.section-container {
  width: 315px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.unbugCharacters.section-container h2 {
  margin: 0;
  margin-left: 10px;
}

.unbugCharacters.section-container .main-divider {
  margin-bottom: 25px;
  margin-top: 15px;
}

.unbugCharacters .debug-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 25px;
  margin-bottom: 30px;
  padding: 0 8px;
}

.unbugCharacters .debug-container .flex {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.unbugCharacters .debug-container .flex p {
  margin-right: 20px;
  font-size: 14px;
}

.unbugCharacters .info {
  color: #6e594f;
  text-align: center;
  margin: auto;
  max-width: 245px;
  font-size: 14px;
}

/* Ranking */
#ranking {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
}

#ranking .section-container {
  max-width: 870px;
  padding: 20px;
  padding-top: 10px;
  padding-top: 0;
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}

#ranking .section-container .image {
  height: 100%;
}

#ranking .section-container th {
  border: none;
  width: 0;
}

#ranking .section-container .table {
  max-width: 550px;
}

#ranking .section-content {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#ranking .illustrations-ranking {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100vh;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
}

#ranking .first {
  left: 0;
}

#ranking .second {
  right: 0;
}

#ranking .arrow {
  padding: 20px;
  cursor: pointer;
}

#ranking .pages {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: #ffd3ab;
}

#ranking .pages span {
  opacity: 0.5;
}

#ranking .pages .page, #ranking .pages .lastPage {
  cursor: pointer;
  opacity: 0.5;
}

#ranking .pages .page.active, #ranking .pages .lastPage.active {
  opacity: 1;
}

#ranking .main-divider {
  margin: 10px 0;
}

#ranking .cathegories {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  padding: 25px;
  padding-bottom: 0;
  font-family: 'Open Sans';
  font-size: 14px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

#ranking .cathegories .cathegorie {
  padding: 20px 10px;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  color: #ffd3ab;
  white-space: nowrap;
}

#ranking .cathegories .cathegorie:hover {
  background-color: #1c1a19;
}

#ranking .cathegories .cathegorie.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 100%;
  background-color: #e8991b;
}

@media screen and (max-width: 1233px) {
  #ranking .section-container {
    position: relative;
    z-index: 2;
  }
}

@media screen and (max-width: 768px) {
  #ranking .illustrations-ranking {
    display: none;
  }
}

/* Scrollbar */
.demo-vertical-track {
  border: 1px solid #6e594f;
  width: 15px;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  margin-left: 20px;
  padding: 2px;
  z-index: 3;
}

.demo-vertical-handler {
  width: 9px;
  background-color: #6e594f;
  margin: 2px 0;
}

.demo-square {
  display: none;
}

.FreeScrollbar-container {
  scroll-behavior: auto;
}

.demo-horizontal-track {
  border: 1px solid #6e594f;
  height: 15px;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  margin-right: 20px;
  padding: 2px;
}

.demo-horizontal-handler {
  height: 9px;
  background-color: #6e594f;
  margin: 0 2px;
  z-index: 3;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #171315;
  color: #fff;
  font-family: 'Myriad';
  margin: auto;
  position: relative;
}

.App {
  max-width: 100vw;
  overflow-x: hidden;
}

h2 {
  font-family: 'Friz Quadrata';
}

.image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.illustrations {
  position: absolute;
  bottom: 0;
  margin-top: 50px;
  max-width: 100%;
  overflow: hidden;
  /*
    .illustration {
        height: 400px;
    }

    .illustration.big {
        height: 500px;
    }
    */
}

.illustrations .flex {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100vw;
  position: relative;
  z-index: 2;
}

.illustrations .img-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: -webkit-gradient(linear, left bottom, left top, from(#0a0a0a), color-stop(50%, transparent));
  background: linear-gradient(to top, #0a0a0a, transparent 50%);
}

.image-bg {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  -webkit-box-shadow: 0 0 1000px 200px black inset;
          box-shadow: 0 0 1000px 200px black inset;
  opacity: 0.5;
}

.subtitle {
  font-size: 18px;
}

.body {
  font-family: 'Open Sans';
  font-size: 11px;
}

.title2 {
  font-size: 20px;
  font-weight: 700;
}

.title3 {
  font-size: 21px;
  font-weight: 900;
}

.info {
  color: #e8991b;
  font-family: 'Friz Quadrata';
  font-size: 12px;
}

a {
  color: #fff;
  text-decoration: none;
  display: inline-block;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#notFund .section-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 100px;
}

.section {
  min-height: 100vh;
  padding: 150px 30px;
  position: relative;
  background-color: #171315;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.section .section-content {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.section .section-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.section-transition {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #171315;
  z-index: 999;
}

.section-container {
  background-color: #171315;
  padding: 30px;
  -webkit-box-shadow: 0 0 30px #000;
          box-shadow: 0 0 30px #000;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  border-radius: 15px;
}

.section-container .main-divider {
  width: 100%;
  height: 1px;
  background-color: #2e262a;
  opacity: 0.5;
  margin-top: 20px;
  margin-bottom: 20px;
}

.section-container h2, .section-container h1 {
  font-size: 20px;
  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 15px;
  font-family: 'Friz Quadrata';
}

.table td {
  border: none;
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.table th {
  border-color: #2a2427;
  font-size: 14px;
  font-weight: 400;
}

.table th .flex {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 5px;
          column-gap: 5px;
}

.table th .chevron {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.table th .chevron.active {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.table th, .table td {
  color: #ffd3ab;
}

@media screen and (max-width: 786px) {
  .illustrations {
    display: none;
  }
}

/* width */
::-webkit-scrollbar {
  width: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: transparent;
}
