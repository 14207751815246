#homeRanking {
    padding: 45px 0;
    position: relative;

    h2 {
        text-align: center;
        z-index: 2;
        position: relative;
        margin-bottom: 25px!important;
    }

    .custom-button {
        position: relative;
        z-index: 2;
        margin: 0 auto;
        margin-top: 30px;
    }

    #ranking-table {
        position: relative;
        z-index: 3;
        max-width: 700px;
        height: 100%;
        width: 100%;
        margin: auto;
        padding-top: 5px;
        padding-left: 35px;
        padding-right: 35px;
        padding-bottom: 30px;
    }
}

@media screen and (max-width: 768px) {
    #homeRanking {
        #ranking-table {
            padding:  0 30px;
            padding-bottom: 40px;

            .table {
                th, td {
                    padding: 5px 10px;
                }
            }
        }
    }
}