.custom-button {
    transition: all 0.3s;
    cursor: pointer;
    color: #492619!important;
    font-family: 'Friz Quadrata';
    font-weight: 700;
    letter-spacing: -0.8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    box-shadow: 0px 3px 7px 0px rgba(5, 0, 6, 0.35);
    text-shadow: 0px 3px 7px rgba(5, 0, 6, 0.35);
    white-space: nowrap;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        transition: all 0.1s;
        top: 0;
        left: 0;
    }

    &.disable {
        filter: grayscale(100%);
        cursor: not-allowed;
    }

    &:hover {
        filter: brightness(110%);
        box-shadow: 0 0 10px 0 #fff inset;

        &::after {
            box-shadow: 0 0 10px 0 #fff;
        }
    
        &.disable {
            filter: grayscale(100%);
            box-shadow: 0 0 0 0 #fff inset;

            &::after {
                box-shadow: 0 0 0 0 #fff;
            }
        }
    }
}