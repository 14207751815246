.input {
    
    label {
        margin-bottom: 5px;
        display: block;
        font-size: 14px;
        font-family: 'Open Sans';
    }

    .input-container {
        border: solid 1px #6e594f;
        background-color: #131011;
        padding: 10px;
        height: 40px;
        width: 300px;
        display: flex;
        align-items: center;
    }

    .divider {
        margin: 0 6px;
        height: 12px;
        width: 1px;
        background-color: #1f1a1c;
    }

    input {
        border: none;
        background-color: #131011;
        outline: none;
        color: #fff;
        max-height: 100%;
        width: 100%;
        font-size: 14px;

        &::placeholder {
            color: #1f1a1c;
        }
    }


    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #131011 inset;
        border: none;
        -webkit-text-fill-color: #fff;
        font-weight: 400;
        transition: background-color 5000s ease-in-out 0s;
    }
}

@media screen and (max-width: 768px) {
    .input {
        .input-container {
            width: 100%;
        }
    }
}