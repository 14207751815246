@font-face {
    font-family: 'Open Sans Extra Bold';
    src: url("../assets/fonts/OpenSans/OpenSans-ExtraBold.ttf");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans Bold';
    src: url("../assets/fonts/OpenSans/OpenSans-Bold.ttf");
    font-weight: 800;
    font-style: normal;
}


@font-face {
    font-family: 'Open Sans';
    src: url("../assets/fonts/OpenSans/OpenSans-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Friz Quadrata';
    src: url("../assets/fonts/FrizQ/Fri-Quadrata-TT-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {font-family: "Friz Quadrata TT"; src: url("//db.onlinewebfonts.com/t/8eccce155090104c7902b964cd0e12fe.eot"); src: url("//db.onlinewebfonts.com/t/8eccce155090104c7902b964cd0e12fe.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8eccce155090104c7902b964cd0e12fe.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8eccce155090104c7902b964cd0e12fe.woff") format("woff"), url("//db.onlinewebfonts.com/t/8eccce155090104c7902b964cd0e12fe.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8eccce155090104c7902b964cd0e12fe.svg#Friz Quadrata TT") format("svg"); }

@font-face {
    font-family: 'Myriad';
    src: url("../assets/fonts/Myriad/MyriadPro-regulard.otf");
    font-weight: normal;
    font-style: normal;
} 

@font-face {
    font-family: 'Myriad Light';
    src: url("../assets/fonts/Myriad/MyriadPro-Light.otf");
    font-weight: 300;
    font-style: normal;
}

@import 'news';
@import '../pages/Home/components/NewsCarousel/newsCarousel';
@import '../pages/Home/components/RankingTable/rankingTable';

/* Home */
@import '../pages/Home/home';

/***** COMPONENTS CSS *****/
@import '../components/Form/form';
@import '../components/Input/input';
@import '../components/Button/button';
@import '../components/Select/select';
@import '../components/Navbar/navbar';
@import '../components/Footer/footer';

/* SHOP */
@import '../pages/Shop/components/ItemsCard/itemsCard';
@import '../pages/Shop/components/Filters/filters';
@import '../pages/Shop/shop';

/* Download */
@import '../pages/Download/download';
@import '../pages/Download/Steps/steps';

/* Buy Coins */
@import '../pages/BuyCoins/buyCoins';

/* Daily Rewards */
@import '../pages/DailyRewards/dailyRewards';

/* News */
@import '../pages/News/news';
@import '../pages/Article/article';

/* Whell */
@import '../pages/Whell/whell';
@import '../pages/Whell/components/Roue/roue';

/* User Panel */
@import '../pages/UserPanel/userPanel';
@import '../pages/UserPanel/components/LockReminder/lockReminder';
@import '../pages/UserPanel/components/ShopHistory/shopHistory';
@import '../pages/UserPanel/components/UnbugCharacters/unbugCharacters';

/* Ranking */
@import '../pages/Ranking/ranking';

/* Scrollbar */
@import '../components/Scrollbar/scrollbar';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: #171315;
    color: #fff;
    font-family: 'Myriad';
    margin: auto;
    position: relative;
}

.App {
    max-width: 100vw;
    overflow-x: hidden;
}

h2 {
    font-family: 'Friz Quadrata';
}

.image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.illustrations {
    position: absolute;
    bottom: 0;
    margin-top: 50px;
    max-width: 100%;
    overflow: hidden;

    .flex {
        justify-content: space-between;
        width: 100vw;
        position: relative;
        z-index: 2;
    }

    .img-container::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(to top, #0a0a0a, transparent 50%);    
    }
    /*
    .illustration {
        height: 400px;
    }

    .illustration.big {
        height: 500px;
    }
    */
}

.image-bg {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    box-shadow: 0 0 1000px 200px black inset;
    opacity: 0.5;
}

.subtitle {
    font-size: 18px;
}

.body {
    font-family: 'Open Sans';
    font-size: 11px;
}

.title2 {
    font-size: 20px;
    font-weight: 700;
}

.title3 {
    font-size: 21px;
    font-weight: 900;
}

.info {
    color: #e8991b;
    font-family: 'Friz Quadrata';
    font-size: 12px;
}

a {
    color: #fff;
    text-decoration: none;
    display: inline-block;
}

.flex {
    display: flex;
}

#notFund {
    .section-content {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 100px;
    }
}

.section {
    min-height: 100vh;
    padding: 150px 30px;
    position: relative;
    background-color: #171315;
    display: flex;
    align-items: center;
    justify-content: center;

    .section-content {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    .section-bg-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.section-transition {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #171315;
    z-index: 999;
}

.section-container {
    background-color: #171315;
    padding: 30px;
    box-shadow: 0 0 30px #000;
    width: fit-content;
    margin: auto;
    border-radius: 15px;


    .main-divider {
        width: 100%;
        height: 1px;
        background-color: #2e262a;
        opacity: 0.5;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    h2, h1 {
        font-size: 20px;
        font-weight: 300;
        margin-top: 10px;
        margin-bottom: 15px;
        font-family: 'Friz Quadrata';
    }
}

.table {

    td {
        border: none;
        font-size: 12px;
        padding-top: 3px;
        padding-bottom: 3px;
    }

    th {
        border-color: #2a2427;
        font-size: 14px;
        font-weight: 400;

        .flex {
            align-items: center;
            column-gap: 5px;
        }

        .chevron {
            transition: transform 0.3s ease-in-out;

            &.active {
                transform: rotate(180deg);
            }
        }
    }

    th, td {
        color: #ffd3ab;
    }

}


@media screen and (max-width: 786px) {
    .illustrations {
        display: none;
    }
}
/* width */ 
::-webkit-scrollbar {
    width: 0;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: transparent;
}