#shop {
    .shop-container {
        position: relative;
        max-height: 100vh;
        padding: 0 50px;
    }

    .FreeScrollbar-container {
        inset: 0px -17px 0 0px!important;
        overflow-x: hidden!important;
    }

    .demo {
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(calc(-50% - 16px));
            height: 200px;
            max-width: 1100px;
            width: calc(100% - 20px);
            background: linear-gradient(#17131500, #171315);
            pointer-events: none;
            z-index: 3;
        }
    }

    #filters {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 100%;
        max-width: 780px;
        margin: auto;
    }

    .items {
        position: relative;
        .items-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            max-width: 1155px;
            margin: auto;
            padding-right: 50px;
            max-height: 70vh;
        }
    }
}
@media screen and (max-width: 768px) {
    #filters {
        .filter-container {
            padding-top: 30px;
            padding-bottom: 30px;
            height: 100%;
            .select {
                margin: auto;
                .selected {
                    padding: 0;
                }
            }

            ul {
                flex-direction: column!important;
                row-gap: 30px;
                align-items: center;
            }
        }
    }
}

@media screen and (max-width: 568px) {
    #shop {

        .shop-container {
            padding: 0;
            max-height: initial!important;

            .items {
                position: relative;
        
                .items-container {
                    display: flex;
                    flex-direction: column;
                    max-height: initial!important;
                    align-items: center;
                    overflow: hidden;
                }
            }
        }
    }
}