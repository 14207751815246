#userPanel {
    max-width: 100vw;
    overflow-x: hidden;
    .section-content {
    display: flex;
    justify-content: center;
    column-gap: 22px;
    row-gap: 22px;

    }

    .main-divider {
        height: 2px;
    }

    .change-user-info {
        max-width: 765px;
        margin: auto;
    }
    
    .left, .right {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        align-items: flex-end;
        justify-content: flex-start;
    }

    h2 {
        font-family: 'Frizz'!important;
    }

    .header {
        justify-content: space-between;
        align-items: center;
    }

    .change-user-info {
        padding-top: 12px;
        padding-bottom: 22px;

        h2 {
            margin: 0;
        }

        .main-divider {
            margin-top: 8px;
            margin-bottom: 15px;
        }
    }

    .inputs {
        column-gap: 20px;
        flex-wrap: wrap;

        label {
            text-transform: capitalize;
        }

        .input-container {
            width: 220px;
        }
    }
}

@media screen and (max-width: 768px) {
    #userPanel {

        .section-content {
            flex-direction: column;
        }

        .header {
            flex-direction: column;
            align-items: center;
            row-gap: 20px;
            margin-bottom: 20px;
        }

        .inputs {
            flex-direction: column;
            align-items: center;
            row-gap: 20px;
        }

        .change-user-info {
            width: 100%;
        }
    }
}