.lockReminder {
    height: 100%;
    width: 100%;
    max-width: 315px;



    .info {
        color: #f6b66b;
    }

    &.section-container {
        margin: auto;
        width: 100%;

        h2 {
            margin: 0;
            margin-left: 10px;
        }
    
        .main-divider {
            margin-bottom: 25px;
            margin-top: 15px;
        }
    }
}