#whell {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    padding-bottom: 0;
    padding-top: 0;

    .whell-container {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 50px;
        min-height: 100vh;
        padding-top: 100px;
    }

    h1 {
        text-align: center;
        margin: 0;
    }

    .main-divider {
        margin-top: 12px;
        margin-bottom: 30px;
    }

    .section-content {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        column-gap: 50px;
        min-height: 100vh;
    }

    .select {
        margin-top: 8px;
        margin-bottom: 15px;
        width: 90%;
    }

    .section-container {
        margin: 0;
        padding-top: 25px;
        max-width: 350px;
        width: 100%;
    }

    .selected {
        padding-left: 20px;
        
    }

    p {
        text-align: center;
        font-family: 'Open Sans';
        font-size: 14px;
        color: #ffab48;
        .warning {
            font-family: 'Open Sans Extra Bold';
            font-size: 18px;
            display: inline-block;
        }
    }
}

@media screen and (max-width: 1180px) {
    #whell {
        padding-bottom: 100px;
        .whell-container {
            flex-direction: column-reverse;
            row-gap: 100px;
            padding-top: 150px;
        }

        .illustration {
            position: relative;
            bottom: -100px;
        }
    }
}

@media screen and (max-width: 768px) {
    #whell {
        .whell-container {
            flex-direction: column-reverse;
            row-gap: 100px;
        }

        .illustration {
            display: none;
        }
    }
}