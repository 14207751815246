#buyCoins {
    padding-top: 150px;
    padding-bottom: 100px;

    h1 {
        font-family: 'Open Sans';
        font-size: 18px;
        text-transform: uppercase;
        text-align: center;
        z-index: 2;
        position: relative;
        margin-bottom: 20px;
    }

    .paymentMethodes {
        display: flex;
        width: 100%;
        padding: 0 10vw;
        justify-content: center;
        align-items: center;
        column-gap: 20px;

        .paymentMethode {
            padding: 30px 50px;
            background-color: #171315;
            height: 120px;
            width: 310px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            cursor: pointer;
            position: relative;
            transition: all 0.2s ease-in-out;

            &.active {
                box-shadow: inset 0 0 10px #d79f5e;
                background-color: #221d1f;
                &::before {
                    box-shadow: 0 0 10px #d79f5e!important;
                }
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 5px;
                transition: all 0.2s ease-in-out;
            }

            &:hover {

                &::before {
                    box-shadow: 0 0 50px #000;
                }
            }

            img {
                height: 40px;
            }
        }

        .mx {
            img {
                height: 30px;
            }
        }
    }

    .coinsPrices {
        width: 100%;
    }

    .coinPrice {
        display: flex;
        justify-content: space-between;
        border: solid 1px #ffab48;
        border-radius: 5px;
        align-items: center;
        margin: 5px 0;
        width: 100%;
        cursor: pointer;
        background-color: #0f0c0e;

        img {
            height: 20px;
            margin-right: 10px;
        }

        .flex {
            margin: 0 10px;
            flex: 1;
        }

        p {
            font-family: 'Open Sans';
            font-size: 12px;
        }

        .price {
            background-color: #ffab48;
            padding: 10px 25px;
            color: #000;
            font-weight: 700;
            flex: 1;
            max-width: 170px;
            font-family: 'Open Sans Extra Bold';
            font-size: 12px;
            font-weight: 600;
        }
    }

    .coinbase {
        .paymentMethod-img {
            height: 25px!important;
        }
    }

    .stripe, .coinbase, .paysafecard {
        width: 100%;
        margin: 30px auto ;
        max-width: 555px;
        padding: 25px;
        border-radius: 15px;
        font-family: 'Open sans', sans-serif;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .price-container {
            max-width: 430px;
            display: flex;
            align-items: center;
            flex-direction: column;
            row-gap: 14px;

            .code {
                background-color: #0f0c0e;
                border: solid 1px #ffab48;
                border-radius: 10px;
                padding: 15px 20px;
                display: flex;
                max-width: 345px;
                width: 100%;

                input {
                    border: none;
                    background-color: #0f0c0e;
                    outline: none;
                    color: #fff;
                    max-height: 100%;
                    width: 100%;
                    font-size: 14px;
                    margin-left: 15px;
                    font-family: 'Open Sans';
                }
            
            
                input:-webkit-autofill,
                input:-webkit-autofill:hover, 
                input:-webkit-autofill:focus {
                    -webkit-box-shadow: 0 0 0px 1000px #0f0c0e inset;
                    border: none;
                    -webkit-text-fill-color: #fff;
                    font-weight: 400;
                    transition: background-color 5000s ease-in-out 0s;
                }
            }
        }
        
        .paymentMethod-img {
            height: 35px;
        }



        .main-divider {
            margin: 10px 0;
        }

        .info {
            font-size: 12px;
            max-width: 500px;
            text-align: center;
        }

        .term {
            max-width: 220px;
            font-size: 12px;
            text-align: center;
            font-family: 'Open Sans';
        }

        button, .button {
            width: 140px;
            height: 40px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
        }
    }

    .select {
        border: solid 1px #e8991b;
        border-radius: 5px;
        width: fit-content;
        max-width: 345px;
        margin: 0;
        width: 100%;

        .options {
            font-size: 13px;
            .option {
                padding: 15px;
            }
        }

        .selected {
            display: flex;
            align-items: center;
            font-family: 'Open Sans';
            font-size: 13px;
        }

        .gold-img {
            height: 20px;
            width: 20px;
            margin-right: 20px;
            margin-left: 15px;
        }
    }
}

@media screen and (max-width: 788px) {
    #buyCoins {
        .paymentMethodes {
            flex-direction: column;

            .mx {
                margin: 30px 0;
            }
        }
    }
}