#dailyRewards {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    h1 {
        font-weight: 100;
        margin: 0;
    }

    .section-content {
        display: flex;
        justify-content: center;
    }

    .section-container {
        padding-top: 25px;
        padding-left: 45px;
        padding-right: 45px;
        padding-bottom: 0;
        max-width: 625px;
        width: 100%;
        margin: 0;
    }
    
    .rewards {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        justify-content: space-between;
        width: fit-content;
        margin: auto;
        max-width: 500px;
        column-gap: 25px;
        row-gap: 65px;
        margin-top: 35px;
        margin-bottom: 60px;

        .reward {
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            background: rgba(23,19,21,1);

            &::before {
                content: '';
                position: absolute;
                width: 52px;
                height: 52px;
                background: linear-gradient(180deg, rgba(135,101,64,1) 0%, rgba(23,19,21,1) 50%, rgba(135,101,64,1) 100%);
            }

            &::after {
                content: '';
                position: absolute;
                width: 50px;
                height: 50px;
                background: rgba(23,19,21,1);
            }

            img {
                position: relative;
                z-index: 2;
            }

            &.win {
                border: solid 1px #876540;
                animation: rewardsWin 3s ease-in-out infinite;
                cursor: pointer;
                position: relative;

                &::before {
                    background: transparent;
                }

                &::after {
                    content: 'YOU WIN  THIS';
                    position: absolute;
                    bottom: -100%;
                    left: 50%;
                    color: #a7885a;
                    width: 90px;
                    height: auto;
                    transform: translateX(-50%);
                    font-family: 'Friz Quadrata';
                    font-size: 16px;
                    line-height: 18px;
                    background: transparent;
                }
            }
        }
    }

    .info {
        max-width: 500px;
        font-size: 18px;
        font-family: 'Open Sans';
        line-height: 18px;
    }
}

@keyframes rewardsWin {
    0% {
        box-shadow: 0 0 10px #f6b56b;
    }
    50% {
        box-shadow: 0 0 30px #f6b56b;
    }
    100% {
        box-shadow: 0 0 10px #f6b56b;
    }
}