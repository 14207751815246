.select {
    position: relative;
    text-align: center;
    border: solid 1px #e8991b;
    border-radius: 5px;
    width: fit-content;
    margin: 20px auto;
    background-color: #0f0c0e;

    .selected {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .arrow {
        width: 25px;
        transform: rotate(90deg);
        transition: all 0.3s ease-in-out;

    }

    &.active {
        .arrow {
            transform: rotate(-90deg);
        }
    }
    
    .img-container {
        height: 100%;
        width: 50px;
        margin-left: 20px;
        background-color: #ffab48;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
    }

    .options-container {
        transition: all 0.5s cubic-bezier(0.43, 0.13, 0.23, 0.96);
        z-index: 99;
        position: relative;
    }
    .options {
        background-color: #1d1a1b;
        padding: 15px 8px;
        position: absolute;
        top: 150%;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
        overflow: hidden;
        z-index: 99;

        .option {
            padding: 7px 30px!important;
            text-align: center;
            cursor: pointer;
            white-space: nowrap;

            &.active {
                color: #ffab48;
            }

            &:hover {
                background-color: #171313;
            }
        }
    }
}