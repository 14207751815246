#newsCard {
    max-width: 640px;
    margin: 0 auto ;
    font-family: 'Open Sans';
    position: relative;
    z-index: 3;

    .article-img {
        width: 100%;
        height: 280px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        align-items: flex-end;
    }

    h3 {
        margin-bottom: 10px;
    }

    .flex {
        align-items: center;
        .container {
            color: #787878;
        }
    }

    .date {
        color: #787878;
    }

    .author {
        color: #997c6d;
    }

    .divider {
        margin: 0 20px;
        height: 10px;
        width: 1px;
        background-color: #787878;
    }

    .text {
        font-size: 11px;
    }

    .button, button {
        width: 75px;
        height: 25px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;

    }
}

@media screen and (max-width: 768px) {
    #newsCard {
        max-width: 80%;
    }
}