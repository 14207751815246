nav {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 50px 150px;
    z-index: 9999;



    &::after {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        height: 0;
        width: 100vw;
        backdrop-filter: blur(0);
        z-index: -1;
        transition: height 0s 0.5s, backdrop-filter 0.5s;
    }

    &.showSecondNav {
        &::after {
            transition: backdrop-filter 0.5s;
            height: 100vh;
            backdrop-filter: blur(5px);
        }
    }



    .user {
        position: relative;

        .secondNav {
            background-color: #0a0808;
            padding: 15px 0;
            border-radius: 5px;
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translate(40%, 105%);
            display: flex;
            flex-direction: column;
            width: 165px;

            .main-divider {
                opacity: 0.5;
                background-color: #fff;
                height: 1px;
                width: 80%;
                margin: 10px auto;
            }

            button {
                background-color: transparent;
                color: #f6b66b;
                border: none;
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 100;
                font-family: 'Friz Quadrata';
            }
            
            ul {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0 7px;

                li {
                    width: 100%;
                    &.active {
                        color: #ffab48;
                    }

                    a {
                        font-family: 'Friz Quadrata';
                        width: 100%;
                        text-align: center;
                        display: block;
                        white-space: nowrap;
                        font-size: 12px;
                        padding: 6px;

                        &:hover {
                            background-color: #171313;
                        }
                    }
                }
            }
        }

        .info {
            margin: 0!important;
        }

        .flex {
            align-items: center;
            column-gap: 20px;

            .flex {
                column-gap: 5px;
                cursor: pointer;
            }
        }

        .chevron {
            height: 7px;
            width: 7px;
            border-right: 1.5px solid #ffab48;
            border-bottom: 1.5px solid #ffab48;
            transform: rotate(45deg);
            transition: transform 0.3s ease-in-out;

            &.active {
                transform: rotate(-135deg);
            }
        }

        .welcome {
            white-space: nowrap;
            font-size: 16px;
            font-family: 'Friz Quadrata';

            .info {
                font-size: 16px;
            }
        }

        .pp {
            min-height: 45px;
            min-width: 45px;
            border-radius: 100px;
        }
    }

    .nav-container {
        width: 100%;
        margin-right: 30px;
    }

    ul {
        display: flex;
        list-style: none;
        align-items: center;
        justify-content: space-between;
        max-width: 455px;
        width: 100%;

        a {
            font-family: 'Friz Quadrata';
            font-size: 16px;
        }

        li {
            &.active {
                a {
                    color: #f6b66b;
                }
            }
        }

        span {
            height: 10px;
            width: 1px;
            background-color: #443f43;
            display: block;
        }

    }

    .auth, .auth-nav {
        display: flex;
        align-items: center;

        .button-light {
            white-space: nowrap;
            color: #f6921e;
            font-family: 'Friz Quadrata';
            font-size: 16px;
        }

        span {
            margin: 0 25px;
            font-size: 12px;
            font-family: 'Friz Quadrata';
        }
    }

    .auth-nav {
        flex-direction: column;
        align-items: center;
        row-gap: 30px;
        margin-top: 50px;

        .button-light {
            font-size: 25px;
        }

        .button, button {
            font-size: 25px;
        }
    }
}

@media screen and (min-width: 787px) {
    nav {
        .menu-icon {
            display: none;
        }

        .auth-nav {
            display: none!important;
        }
    }
}

@media screen and (max-width: 786px) {
    nav {
        padding: 30px 30px;
        position: fixed;

        .menu-icon-container {
            height: 50px;
            width: 50px;
            position: relative;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        .menu-icon {
            width: 45px;
            height: 2px;
            background-color: #fff;
            z-index: 2;
            position: relative;
            transition: all 0.5s ease-in-out;
            border-radius: 10px;

            &::after {
                content: '';
                height: 2px;
                width: 45px;
                position: absolute;
                top: 0;
                left: 0;
                background-color: #fff;
                transform: translateY(-12px);
                transition: all 0.5s ease-in-out;
                border-radius: 10px;
            }

            &::before {
                content: '';
                height: 2px;
                width: 45px;
                position: absolute;
                top: 0;
                left: 0;
                background-color: #fff;
                transform: translateY(12px);
                transition: all 0.5s ease-in-out;
                border-radius: 10px;
            }

            &.active {
                background-color: transparent;
                transform: rotate(135deg);


                &::after {
                    transform: translateY(0);
                    transform: rotate(180deg);

                }
    
                &::before {
                    transform: translateY(0);
                    transform: rotate(90deg);
                }
            }
        }

        .nav-container {
            transform: translateY(-100%);
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background-color: #171313;
            transition: transform 1s  cubic-bezier(0.83, 0, 0.16, 0.99);

            ul {
                width: 100%;
                height: 100%;
                align-items: center;
                justify-content: space-around;
                padding: 20vh 0;
                margin: auto;

                li {
                    a {
                        font-size: 30px;
                    }
                }
             }

            span {
                display: none;
            }

            &.show {
                transform: translateY(0);
            }
        }

        .user {
            .welcome {
                display: none;
            }

            .secondNav {
                transform: translate(10%, 105%);
            }
        }

        ul {
            flex-direction: column;
        }

        .auth {
            display: none;
        }
    }
}

