.steps {
    display: flex;
    width: 100%;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;

    .section-container {
        padding: 13px 35px;
        column-gap: 40px;
        row-gap: 40px;
        align-items: center;
        margin-bottom: 45px;
    }

    .step {
        display: flex;
        align-items: center;

        .step-container {
            display: flex;
            align-items: center;

        }

        .num {
            font-family: 'Open Sans Extra Bold';
            font-size: 80px;
            margin-right: 10px;
            line-height: 80px;
        }

        .text {
            max-width: 160px;

            .title {
                color: #f6b66b;
                font-size: 12px;
                font-family: 'Open Sans Bold';
            }

            .description {
                font-family: 'Open Sans';
                font-size: 10px;
            }
        }
    }

    .divider {
        height: 1px;
        width: 45px;
        background-color: #2e262a;
    }
}

@media screen and (max-width: 900px) {
    .steps {
        .steps-container {
            padding: 20px 40px;
        }
    }
}

@media screen and (max-width: 768px) {
    .steps {
        .steps-container {
            flex-direction: column;
        }

        .step {
            flex-direction: column;
            .divider {
                height: 70px;
                width: 1px;
            }
        }
    }
}