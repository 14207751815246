#download {    
    .downloads {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: fit-content;
        margin: auto;
        row-gap: 12px;
        margin-bottom: 40px;
    }

    h4 {
        margin-bottom: 8px;
        font-family: 'Friz Quadrata';
        font-weight: 400;
        font-size: 16px;
    }

    h5 {
        margin-bottom: 15px;
        font-weight: 400;
        text-align: center;
        font-size: 16px;
        font-family: 'Friz Quadrata';
    }

    .custom-button {
        width: 290px;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0;
        padding-left: 50px;
        font-family: 'Friz Quadrata';
        color: #492619;
        font-size: 17px;
        text-align: center;
        justify-content: flex-start;

        &.disable {
            img {
                margin-right: 25px;
            }
        }

        img {
            margin-right: 10px;
        }
    }

    .paragraphs {
        display: flex;
        width: 100%;
        column-gap: 90px;
        align-items: center;
        justify-content: center;
        font-size: 10px;

        .section-container {
            margin: 0;
            max-width: 300px;
            width: 100%;
        }
    }
}

@media screen and (max-width: 768px) {
    #download {
        .paragraphs {
            flex-direction: column;
            margin: auto;
            width: fit-content;

            .section-container {
                margin: 0!important;
                margin-top: 20px!important;
                width: 100%!important;
            }

            .mt {
                margin-top: 20px;
            }
        }
    }
}