.shopHistory {
    position: relative;
    max-width: 765px;
    margin: auto;


    
    &.section-container {
        margin: 0;
        width: 100%;

        .main-divider {
            margin-bottom: -2px;
            z-index: 3;
            position: relative;
            background-color: #171315;
            padding-top: 20px;
            margin-top: 0;
            border-bottom: 2px solid #2e262a;
            width: calc(100% - 25px);
        }
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 29px;
        left: 0;
        height: 100px;
        width: 100%;
        background: linear-gradient(#17131500, #171315);
        pointer-events: none;
    }

    
    .table-container{
        height: 100%;
        padding-right: 20px;
        position: relative;

        thead {
            position: sticky;
            top: -10px;
            background: linear-gradient(#171315 50%, #17131500);
            border: none;

            th {
                color: #f6b66b;
                border: none;
                text-align: center;
            }
        }

        .table {
            td {
                text-align: center;
            }

            .id {
                text-align: left!important;
            }
        }
    }
}