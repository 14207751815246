#roue {
    //position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    max-height: 600px;
    transform: translateY(25px);



    .dragon {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%) translateY(-75%);
        z-index: 99;
    }
    
    .selectedImg {
        position: absolute;
        top: 0;
    }

    .spinner-container {
        position: absolute;
        z-index: 3;
        cursor: pointer;
    }

    .roue-shadow-container {
        height: 100%;
        width: 100%;
        overflow: hidden;
        border-radius: 1000px;
    }

    .well-bg {
        position: absolute;
        height: 100%;
        width: 100%;
        max-width: 500px;
        max-height: 500px;
        transition: all 5s ease-in-out;
    }

    .items {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 5s ease-in-out;
        max-width: 500px;
        max-height: 500px;
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 3;
        transition: all 5s ease-in-out;

        img {
            cursor: pointer;
            height: 25px;
            width: 25px;
            transition: all 2s ease-in-out;
            z-index: -1;

            &.won {
                filter: drop-shadow( 0 0 5px  #ff9d00);
                animation: itemWin 3s infinite;
            }
        }
    }
}

.itemWin {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.162);
    backdrop-filter: blur(5px);
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: showItemWin 0.5s;

    .section-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        

        h2 {
            font-family: 'Myriad', sans-serif;

        }

        .main-divider {
            margin-bottom: 50px;
            margin-top: 15px;
        }

        img {
            margin-bottom: 10px;
            filter: drop-shadow( 0 0 5px  #ff9d00);
            animation: itemWin 3s infinite;
        }

        p {
            margin-bottom: 30px;
            color: #ff9d00;
            font-family: 'Open Sans', sans-serif;

        }
    }

}

.information {
    //background-color: #171315;
    background-color: #1c1515;
    padding: 7px 5px;
    border-radius: 5px;
    border: solid 1px #e8991b;
    font-size: 9.59px;
    position: absolute;
    transform: translate(-60%, -150%);
    opacity: 1;
    transition: all 0.3s ease-in-out;
    z-index: 999999;


    span {
        white-space: nowrap;
        font-family: 'Open Sans', sans-serif;
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 55%) rotate(45deg);
        border-bottom: solid 1px #e8991b;
        border-right: solid 1px #e8991b;
        background-color: #1c1515;
        height: 15px;
        width: 15px;
        border-end-end-radius: 3px;
        z-index: 99;
    }
}

@keyframes showItemWin {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes itemWin {
    0% {
        filter: drop-shadow( 0 0 5px  #ff9d00);
    }
    50% {
        filter: drop-shadow( 0 0 0px  #ff9d00);
    }
}