#home-news {
    padding: 30px 0;
    padding-top: 65px;
    position: relative;

    h2 {
        text-align: center;
        z-index: 2;
        position: relative;
    }

    article {
        z-index: 3;
        position: relative;
    }

    .arrow {
        padding: 20px;
        cursor: pointer;
    }

    .pages {
        display: flex;
        column-gap: 10px;
        align-items: center;
        justify-content: center;
        user-select: none;
        z-index: 3;
        position: relative;
        color: #ffd3ab;

        span {
            opacity: 0.5;
        }

        .page, .lastPage {
            cursor: pointer;
            opacity: 0.5;
            
            &.active {
                opacity: 1;
            }
        }
    }
}