#article {
    font-family: 'Open Sans';
    padding: 0;
    padding-bottom: 65px;
    display: flex;
    justify-content: center;

    .section-bg-img {
        max-height: 100vh;
    }


    .illustrations-news {
        height: 100vh;
        width: 100%;
        justify-content: space-between;
        align-items: flex-end;
        position: absolute;
        top:0;
        left: 0;

        .illustration-news-1 {
            height: 440px;
            width: 350px;
        }
        .illustration-news-2 {
            height: 440px;
            width: 300px;
        }
    }

    .section-container {
        margin-top: 200px;
        max-width: 700px;
        padding-top: 35px;
        padding-left: 55px;
        padding-right: 55px;
        padding-bottom: 30px;
        position: relative;
        z-index: 2;

        h1 {
            font-size: 38px;
            font-family: 'Open Sans';
            margin: 0;
        }

        .authorDate {
            align-items: center;
        }
    
        .author {
            color: #997c6d;
            span {
                color: #838383;
            }
        }
    
        .date {
            color: #838383;
        }

        .flex {
            margin: 10px 0;
        }

        .divider {
            margin: 0 20px;
            width: 1px;
            height: 15px;
            background-color: #838383;
        }
    
        img {
            width: 100%;
            margin: 30px 0;
        }
    
        .paragraphs {
            max-width: 600px;
            font-size: 13.5px;
        }
        
        button, .button {
            width: fit-content;
            margin: 0 auto!important;
            margin-top: 120px!important;
        }
    }

    .section {
        min-height: 75vh;
    }

    .big-divider {
        position: absolute;
        left: 0;
        top: 99vh;
        width: 100%;
        height: 10px;
        background-color: #c08e55;
    }

}

@media screen and (max-width: 768px) {
    #article {
        .illustrations-news {
            display: none;
        }
        .section-container {
            margin: 50px auto;
            margin-top: 200px;
            width: 95%;


        }
    }
}