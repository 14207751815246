#filters {

    .section-container {
        padding: 0;
    }

    .filter-container {
        margin-bottom: 20px;
        width: 780px;
        height: 88px;
        font-family: 'Open sans';
        font-size: 14px;
        padding: 0 70px;
        display: flex;
        align-items: center;

        .select {
            border: none;
            background-color: transparent;
            margin: 0;
            .selected {
                margin: 0;
            }
        }
    }

    .flex {
        width: 100%;
        justify-content: space-between;
        list-style: none;

        .selected {
            &::after {
                content: '';
                position: absolute;
                height: 6px;
                width: 6px;
                border-left: 1.5px solid #fff;
                border-bottom: 1.5px solid #fff;
                right: -15px;
                transform: rotate(-45deg);
                transition: transform 0.3s ease-in-out;
            }
        }

        .activeMenu {

            .active {
                .selected {
                    &::after {
                        transform: rotate(135deg) translateY(-50%);
                    }
                }
            }

            .selected {
                color: #ffab48;
                position: relative;
                &::after {
                    border-left: 1.5px solid #ffab48;
                    border-bottom: 1.5px solid #ffab48;
                }
            }
        }
        
        li {
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &:hover {
                color: #ffab48;
            }
        }
    }
}