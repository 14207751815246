.form {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    
    h1 {
        text-align: center;
        font-family: 'Friz Quadrata';
        text-transform: uppercase;
        font-size: 20px;
    }

    .inputs {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }

    .spacer {
        height: 1px;
        width: 100%;
        background-color: #6e594f;
        opacity: 0.5;
        margin-top: 15px;
        margin-bottom: 35px;
    }

    .info {
        color: #6e594f;
        text-align: center;
        font-size: 12px;
        margin-top: 10px;
        font-family: 'Open Sans';
        max-width: 300px;
    }

    .form-container {
        padding-top: 25px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
        background-color: #171315;
        border-radius: 10px;
    }

    .button, button {
        margin: auto;
        margin-top: 45px;
        margin-bottom: 23px;
    }
}