.unbugCharacters {

    &.section-container {
        width: 315px;
        padding-top: 25px;
        padding-bottom: 25px;
        h2 {
            margin: 0;
            margin-left: 10px;
        }
    
        .main-divider {
            margin-bottom: 25px;
            margin-top: 15px;
        }
    }

    .debug-container {
        display: flex;
        flex-direction: column;
        row-gap: 25px;
        margin-bottom: 30px;
        padding: 0 8px;

        .flex {
            justify-content: space-between;
            align-items: center;
            p {
                margin-right: 20px;
                font-size: 14px;
            }
        }
    }

    .info {
        color: #6e594f;
        text-align: center;
        margin: auto;
        max-width: 245px;
        font-size: 14px;
    }
}